import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../../../../helper/ApiHelper';
import { Grid, IconButton, Dialog, DialogTitle, DialogContent, Table, TableContainer, TableBody, TableCell, TableRow, Paper } from '@mui/material';
import { Close } from '@mui/icons-material';
import { LinkComponent, TableHeadingContent, SimpleCheckbox } from '../../../../Reusable';
import AddDocumentation from './AddDocumentation';
import { IExecutorDocumentationModel } from './ExecutorDocumentationModel';

interface IProps {
    open: boolean,
    denyEdit : boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ExecutorDocumentation(props : IProps) {
    const { open, setOpen, denyEdit } = props;
    const execUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "executor-management/executor/";

    const { t } = useTranslation();

    const [mainModel, setMainModel] = useState<IExecutorDocumentationModel[]>([]);
    const [deleteList, setDeleteList] = useState<number[]>([]);
    const [addDialogOpen, setAddDialogOpen] = useState(false);

    useEffect(() => {
        if (open)
            getData()
    }, [open]);

    const getData = async () => {
        const data = await API.get(`${execUrl}documentations`);
        setMainModel(data);
        setDeleteList([]);
    }

    const deleteDocumentation = async () => {
        await API.post(`${execUrl}documentation/delete`, deleteList);
        getData();
    }

    const checkForDeletion = (id : number) => {
        if (!deleteList.some(x => x == id))
            deleteList.push(id);
        else
            deleteList.splice(deleteList.indexOf(id), 1);
        setDeleteList([...deleteList]);
    }

    return (
        <Dialog
            aria-labelledby="transition-modal-title"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
        >
            <AddDocumentation
                open={addDialogOpen}
                setOpen={setAddDialogOpen}
                refresh={getData}
            />
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <h3 style={{ margin: "0px" }}>{t('Documentation')}</h3>
                    </Grid>
                    <Grid item>
                        <IconButton color="inherit" onClick={() => setOpen(false)} size="large">
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container direction="column">
                    <Grid item>
                        <TableHeadingContent
                            clickDelete={deleteDocumentation}
                            clickAdd={() => setAddDialogOpen(true)}
                            denyEdit={denyEdit}
                        />
                    </Grid>
                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table aria-label="spanning table">
                                {mainModel && mainModel.length > 0 &&
                                    <TableBody>
                                        {mainModel.map((link, index) =>
                                            <TableRow key={index + 1}>
                                                {!denyEdit &&
                                                    <TableCell style={{width: "10%"}}>
                                                        <SimpleCheckbox
                                                            id={"docu-checkbox" + index}
                                                            key={Math.random()}
                                                            defaultChecked={deleteList?.some(x => x == link.id)}
                                                            color="default"
                                                            onChange={() => checkForDeletion(link.id)}>
                                                        </SimpleCheckbox>
                                                    </TableCell>
                                                }
                                                <TableCell>
                                                    <LinkComponent
                                                        newTab
                                                        link={link.url}
                                                        innerComponent={link.name}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                }
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}