import { checkEmail, checkIsNotNullOrWhitespace, checkPhoneNumber, checkIsAnythingSelectedObject, checkIsInteger, checkIsNumber, checkNumberMoreZero } from '../../helper/Validator';
import * as Fmt from '../../helper/Formatters';

export const ProjectSettingsModelFactory = (t) => ({
    measurementSystem: { label: t("MeasurementSystem"), idkey: "PS-MeasurementSystem", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsAnythingSelectedObject] },
    currency: { label: t("Currency"), idkey: "PS-Currency", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    subsidiaryFestoName: { label: t("SubsidiaryFestoName"), idkey: "PS-SubsidiaryFestoName", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    cO2GridAverage: { label: t("CO2GRA") + Fmt.getUnitInBracket("co2WheightPerKwh", t), idkey: "PS-CO2GRA", value: "", isValid: true, validationError: '', validators: [] },
    subsidiaryAddress: { label: t("SubsidiaryAddress"), idkey: "PS-SubsidiaryAddress", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    subsidiaryCountry: { label: t("SubsidiaryCountry"), idkey: "PS-SubsidiaryCountry", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    subsidiaryCity: { label: t("SubsidiaryCity"), idkey: "PS-SubsidiaryCity", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    subsidiaryTelephone: { label: t("SubsidiaryTelephone"), idkey: "PS-SubsidiaryTelephone", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkPhoneNumber] },
    leakageDetectionCosts: { label: t("LeakageDetectionCosts") + Fmt.getUnitWithCurrency(t("UnitDay"), t), idkey: "PS-LeakageDetectionCosts", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsInteger], },
    repairLabourCosts: { label: t("RepairLabourCosts") + Fmt.getUnitWithCurrency("hours", t), idkey: "PS-RepairLabourCosts", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsInteger], },
    travelTimeCostsPerHour: { label: t("TravelTimeCostsPerHour") + Fmt.getUnitWithCurrency("hours", t), idkey: "PS-TravelTimeCostsPerHour", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsInteger], },
    sparePartsCosts: { label: t("SparePartsCosts") + Fmt.getUnitWithCurrency(t("leakage"), t), idkey: "PS-SparePartsCosts", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsInteger], },
    costsPerDistanceUnit: { label: t("CostsPerDistance") + Fmt.getUnitWithCurrency("unitKmMile", t), idkey: "PS-CostsPerkWh", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsNumber], },
    lumpSum: { label: t("LumpSum") + Fmt.getUnitWithCurrency(t("UnitDay"), t), idkey: "PS-LumpSum", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsInteger], },
    equipmentServiceLife: { label: t("EquipmentServiceLife"), idkey: "PS-EquipmentServiceLife", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsInteger] },
    taxOnProfit: { label: t("TaxOnProfit") + Fmt.getPercentage(), idkey: "PS-TaxOnProfit", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsInteger] },
    settingsLeakageDetectedDayFlow: { label: t("SettingsLeakageDetectedDayFlow") + Fmt.addBrackets(`l/min/${t("UnitDay")}`, t), idkey: "PS-SettingsLeakageDetectedDayFlow", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsNumber] },
    settingsLeakageDetectedDayPerTechnician: { label: t("SettingsLeakageDetectedDayPerTechnician") + Fmt.addBrackets(`${t("leakage")}/${t("UnitDay")}`, t), idkey: "PS-SettingsLeakageDetectedDayPerTechnician", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsNumber] },
    settingsRepairTimePerLeakage: { label: t("SettingsRepairTimePerLeakage") + Fmt.addBrackets("min", t), idkey: "PS-SettingsRepairTimePerLeakage", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsNumber] },
    settingsWorkingHoursPerDayPerDetection: { label: t("SettingsWorkingHoursPerDayPerDetection") + Fmt.getUnitInBracket("hours", t), idkey: "PS-SettingsWorkingHoursPerDayPerDetection", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsNumber] },
    settingsSavings1barReduction: { label: t("SettingsSavings1barReduction") + Fmt.getPercentage(), idkey: "PS-SettingsSavings1barReduction", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsNumber] },

    salutation: { value: 0, idkey: "GI-Salutation", label: t("Salutation"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkNumberMoreZero] },
    firstName: { idkey: "PS-FirstName", isValid: true, label: t("FirstName"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], value: "" },
    lastName: { idkey: "PS-LastName", isValid: true, label: t("LastName"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], value: "" },
    email: { idkey: "PS-Email", label: t("Email"), value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkEmail] },
})
