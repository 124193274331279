import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import ImageViewer from '../../ImageViewer/ImageViewer';
import * as Mapper from '../../../helper/Mappers';
import './Application.scss';
import { TextFieldComponent, NumberInputFieldComponent, HeadingComponent, ButtonComponent, DatePickerComponent, AutocompleteComponent, CheckboxComponent, ListPreviousNext, QrReaderButton } from '../../../components/Reusable/'
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextareaAutosize } from '@mui/material';
import { API } from '../../../helper/ApiHelper';
import { ApplicationModelFactory } from './ApplicationModel';
import ReplacementParts from '../../ReplacementParts/ReplacementParts';
import MeasurementId from '../../MeasurementId/MeasurementId';
import * as Fmt from '../../../helper/Formatters';
import * as ImageBlobConstants from '../../ImageViewer/Constants';
import { OpenGlobalSnackbar, ScrollerToTag } from '../../../helper/GlobalVariables';
import { v4 as uuidv4 } from 'uuid';
import { ExecutorStore, DirtyStore } from '../../../store';
import { validateSingleProperty, checkAndValidateModel } from '../../../helper/Validator';
import * as InputRestrictions from '../../../helper/InputRestrictions';
import { NavigationOption } from '../../../helper/ConstantRepository';

export default function Application(props) {
    const { denyEdit } = props;
    var macacUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "machine-analysis";
    let history = useHistory();
    const { t } = useTranslation();
    const { country, lng, id, cid } = useParams();
    const [mainModel, setMainModel] = useState(ApplicationModelFactory(t));
    const [replacementPartList, setReplacementPartList] = useState([]);
    const [executorUsersOptions, setexecutorUsersOptions] = useState([]);

    useEffect(() => {
        Mapper.updateExistingModelFormattingLabels(mainModel, ApplicationModelFactory(t), setMainModel);
    }, [t]);

    useEffect(() => {
        if (cid > 0) loadExistingData();
        if (cid == 0) loadCleanPage();
    }, [cid]);

    useEffect(() => {
        let execSub = ExecutorStore.executorSettings.subscribe(executorSettings => {
            if (executorSettings && executorSettings.executorUsers) setexecutorUsersOptions(executorSettings.executorUsers);
        })
        return () => execSub.unsubscribe();
    }, []);

    const loadExistingData = () => {
        API.get(`${macacUrl}/${id}/Application/${cid}`).then(resp => {
            let mappedModel = Mapper.mapDataToModelValues(resp.data, ApplicationModelFactory(t));
            let calcMappedModel = Mapper.mapMachingDataToModelValues(resp.calcResults, ApplicationModelFactory(t));
            setMainModel({ ...mappedModel, ...calcMappedModel });
            setReplacementPartList(resp.data.replacementPartList);
            ScrollerToTag()
        })
    }

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const loadCleanPage = () => {
        let guid = Mapper.getNewGuid();
        let newModel = _.cloneDeep(ApplicationModelFactory(t));
        newModel.guid.value = guid;
        setMainModel(newModel);
        setReplacementPartList([]);
        scrollTop();
    }

    const copyForm = async () => {
        let modelCopy = mainModel;
        modelCopy.guid.value = uuidv4();
        modelCopy.id.value = 0;
        modelCopy.measurementId.value = "";
        modelCopy.optimizationCostsActual.value = 0;
        modelCopy.optimizationCostsFuture.value = 0;
        modelCopy.optimizationFiscalDepreciation.value = 0;
        modelCopy.optimizationFlowActual.value = 0;
        modelCopy.optimizationFlowFutureTOTAL.value = 0;
        modelCopy.optimizationFlowSavingsTOTAL.value = 0;
        modelCopy.optimizationInvestment.value = 0;
        modelCopy.optimizationPaybackTime.value = 0;
        modelCopy.optimizationSavingsAfterTaxes.value = 0;
        modelCopy.optimizationSavingsBeforeTaxes.value = 0;
        modelCopy.optimizationTaxProfit.value = 0;
        modelCopy.optimizationTaxableSavings.value = 0;
        modelCopy.optimizationVolumeYearActual.value = 0;
        modelCopy.optimizationVolumeYearFuture.value = 0;
        modelCopy.optimizationVolumeYearSavings.value = 0;
        modelCopy.settingsServiceLife.value = 0;
        setMainModel(modelCopy);
        replacementPartList.forEach(x => x.id = 0);
        scrollTop();
    }

    const clickCancel = () => {
        history.push("/" + country + "/" + lng + '/project/' + id + '/MACAC' + "#Application");
    }

    const postForm = async (navigationOption) => {
        let [isValid, validatedModel] = checkAndValidateModel(mainModel, true);
        setMainModel(validatedModel);
        if (isValid) {
            let objectPostModel = Mapper.extractValuesFromModel(mainModel);
            objectPostModel.replacementPartList = replacementPartList;
            let postModel = {
                projectId: id,
                ProjectApplication: objectPostModel
            };

            let method = mainModel.id.value == 0 ? "post" : "put";

            API({
                url: `${macacUrl}/${id}/Application/${mainModel.id.value}`,
                method,
                data: postModel,
            }).then(async (componentId) => {
                DirtyStore.setIsDirty(false);
                OpenGlobalSnackbar("success", t('SaveSuccessful'));
                switch (navigationOption) {
                    case NavigationOption.Reload:
                        history.push(`/${country}/${lng}/project/${id}/AN/AirReceiver/${componentId}`);
                        break;
                    case NavigationOption.Create:
                        if (cid == 0) loadCleanPage();
                        history.push(`/${country}/${lng}/project/${id}/AN/AirReceiver/0`);
                        break;
                    case NavigationOption.Copy:
                        await copyForm();
                        break;
                    default:
                        break;
                }
            });
        }
        else {
            OpenGlobalSnackbar("danger", t('ModelInvalidError'));
        }
        return isValid;
    }

    const updateModel = (property, value) => {
        mainModel[property].value = value;
        validateSingleProperty(mainModel, property);
        setMainModel({ ...mainModel });
        DirtyStore.setIsDirty(postForm);
    }

    return (
        <div className="application">
            <Grid container spacing={3} direction="column">
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item id="#Application">
                            <HeadingComponent value={t('Application')} size="h6" />
                        </Grid>
                        <Grid item>
                            <ListPreviousNext />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MeasurementId disabled={denyEdit} element={mainModel.measurementId} onChange={e => updateModel("measurementId", e)} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <DatePickerComponent
                                disabled={denyEdit}
                                t={t}
                                model={mainModel.detectedDate}
                                onChange={e => updateModel("detectedDate", e)}
                                maxDate={new Date()}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AutocompleteComponent disabled={denyEdit} options={executorUsersOptions} t={t} model={mainModel.detectedBy} onInputChange={(e, value) => {
                                updateModel("detectedBy", value);
                            }} />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxComponent model={mainModel.isRepairDone} onChange={e => { updateModel("isRepairDone", e.target.checked); }} />
                        </Grid>
                        {mainModel && mainModel.isRepairDone && mainModel.isRepairDone.value &&
                            <React.Fragment>
                                <Grid item xs={12} md={6}>
                                    <DatePickerComponent
                                        t={t}
                                        model={mainModel.repairDate}
                                        onChange={e => updateModel("repairDate", e)}
                                        maxDate={new Date()}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AutocompleteComponent options={executorUsersOptions} t={t} model={mainModel.repairedBy} onInputChange={(e, value) => { updateModel("repairedBy", value); }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextareaAutosize
                                        id='textarea-MCACEDIT-repairComment'
                                        placeholder={t("Comment")}
                                        width="100%"
                                        minRows="5"
                                        type="text"
                                        value={mainModel.repairComment.value}
                                        onChange={e => updateModel("repairComment", e.target.value)}
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                </Grid>
                <Grid item id="#Location">
                    <HeadingComponent value={t('Location')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={mainModel.building} onChange={e => {
                                updateModel("building", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={mainModel.department} onChange={e => {
                                updateModel("department", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={9} md={4}>
                            <TextFieldComponent
                                disabled={denyEdit}
                                t={t}
                                model={mainModel.machine}
                                onChange={e => updateModel("machine", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={3} md={2}>
                            <QrReaderButton
                                setCode={code => updateModel("machine", code)}
                                id="application-machine-qr"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} id="#Savings">
                    <HeadingComponent value={t('Savings')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={5} direction="row">
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2} direction="column">
                                <Grid item xs={12} md={6}>
                                    <HeadingComponent value={t('Actual')} size="h6" />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} id='pressureActual' t={t} model={mainModel.pressureActual} onChange={e => {
                                        updateModel("pressureActual", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} id='optimizationFlowPerCycleActual' t={t} model={mainModel.optimizationFlowPerCycleActual} onChange={e => {
                                        updateModel("optimizationFlowPerCycleActual", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} id='optimizationCycleTimeActual' t={t} model={mainModel.optimizationCycleTimeActual} onChange={e => {
                                        updateModel("optimizationCycleTimeActual", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} id='optimizationNrCyclesMinActual' t={t} model={mainModel.optimizationNrCyclesMinActual} onChange={e => {
                                        updateModel("optimizationNrCyclesMinActual", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} id='optimizationNrComponentsActual' t={t} model={mainModel.optimizationNrComponentsActual} onChange={e => {
                                        updateModel("optimizationNrComponentsActual", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} id='optimizationOperatingHoursActual' t={t} model={mainModel.optimizationOperatingHoursActual} onChange={e => {
                                        updateModel("optimizationOperatingHoursActual", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} id='optimizationOperatingDaysActual' t={t} model={mainModel.optimizationOperatingDaysActual} onChange={e => {
                                        updateModel("optimizationOperatingDaysActual", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2} direction="column">
                                <Grid item xs={12} md={6}>
                                    <HeadingComponent value={t('Future')} size="h6" />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} id='pressureFuture' t={t} model={mainModel.pressureFuture} onChange={e => {
                                        updateModel("pressureFuture", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} id='optimizationFlowPerCycleFuture' t={t} model={mainModel.optimizationFlowPerCycleFuture} onChange={e => {
                                        updateModel("optimizationFlowPerCycleFuture", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} id='optimizationCycleTimeFuture' t={t} model={mainModel.optimizationCycleTimeFuture} onChange={e => {
                                        updateModel("optimizationCycleTimeFuture", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} id='optimizationCycleMinFuture' t={t} model={mainModel.optimizationCycleMinFuture} onChange={e => {
                                        updateModel("optimizationCycleMinFuture", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} id='optimizationNrComponentsFuture' t={t} model={mainModel.optimizationNrComponentsFuture} onChange={e => {
                                        updateModel("optimizationNrComponentsFuture", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} id='optimizationOperatingHoursFuture' t={t} model={mainModel.optimizationOperatingHoursFuture} onChange={e => {
                                        updateModel("optimizationOperatingHoursFuture", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} id='optimizationOperatingDaysFuture' t={t} model={mainModel.optimizationOperatingDaysFuture} onChange={e => {
                                        updateModel("optimizationOperatingDaysFuture", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <TableContainer component={Paper} >
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow className="table-head-row">
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">{t('Actual')}</TableCell>
                                    <TableCell align="left">{t('Future')}</TableCell>
                                    <TableCell align="left">{t('Savings')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('AirFlow') + Fmt.getUnitInBracket("nanoLiterPerMinute", t)}</TableCell>
                                    <TableCell id="td_MCACEDIT-optimizationFlowActual" align="left">{Fmt.round(mainModel.optimizationFlowActual.value, country, 0)}</TableCell>
                                    <TableCell id="td_MCACEDIT-optimizationFlowFuture" align="left">{Fmt.round(mainModel.optimizationFlowFutureTOTAL.value, country, 0)}</TableCell>
                                    <TableCell id="td_MCACEDIT-optimizationFlowSavings" align="left">{Fmt.round(mainModel.optimizationFlowSavingsTOTAL.value, country, 0)}</TableCell>
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('VolumePerYear') + Fmt.getUnitInBracket("cubicMeterOfGas", t)}</TableCell>
                                    <TableCell id="td_MCACEDIT-optimizationVolumeYearActual" align="left">{Fmt.round(mainModel.optimizationVolumeYearActual.value, country, 0)}</TableCell>
                                    <TableCell id="td_MCACEDIT-optimizationVolumeYearFuture" align="left">{Fmt.round(mainModel.optimizationVolumeYearFuture.value, country, 0)}</TableCell>
                                    <TableCell id="td_MCACEDIT-optimizationVolumeYearSavings" align="left">{Fmt.round(mainModel.optimizationVolumeYearSavings.value, country, 0)}</TableCell>
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('CostPerYear') + Fmt.getCurrency()}</TableCell>
                                    <TableCell id="td_MCACEDIT-optimizationCostsActual" align="left">{Fmt.round(mainModel.optimizationCostsActual.value, country, 0)}</TableCell>
                                    <TableCell id="td_MCACEDIT-optimizationCostsFuture" align="left">{Fmt.round(mainModel.optimizationCostsFuture.value, country, 0)}</TableCell>
                                    <TableCell id="td_MCACEDIT-optimizationSavingsBeforeTaxes" align="left">{Fmt.round(mainModel.optimizationSavingsBeforeTaxes.value, country, 0)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} md={6} id="#TotalFutureInvestment">
                    <HeadingComponent value={t('TotalFutureInvestment')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={5} direction="row">
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2} direction="column">
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.optimizationLabourCosts} onChange={e => {
                                        updateModel("optimizationLabourCosts", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.optimizationSparePartsCosts} onChange={e => {
                                        updateModel("optimizationSparePartsCosts", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.optimizationLumpSumCosts} onChange={e => {
                                        updateModel("optimizationLumpSumCosts", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2} direction="column">
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.optimizationtravelTimeCosts} onChange={e => {
                                        updateModel("optimizationtravelTimeCosts", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.optimizationtravelCosts} onChange={e => {
                                        updateModel("optimizationtravelCosts", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2} direction="column">
                                <Grid item xs={12}>
                                    {t('TotalInvestment')}: {mainModel.optimizationInvestment.value} { }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item id="#AnnualSavingsAfterTaxes">
                    <HeadingComponent value={t('AnnualSavingsAfterTaxes')} size="h6" />
                </Grid>
                <Grid item xs={12} md={6} className='annual-savings'>
                    <TableContainer component={Paper} >
                        <Table aria-label="spanning table">
                            <TableBody>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('SavingsBeforeTaxes') + Fmt.getCurrency()}</TableCell>
                                    <TableCell id="td_MCACEDIT-optimizationSavingsBeforeTaxes" align="right">{Fmt.round(mainModel.optimizationSavingsBeforeTaxes.value, country, 0)}</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('ServiceLifeOfEquipment')}</TableCell>
                                    <TableCell id="td_MCACEDIT-settingsServiceLife" align="right">{Fmt.round(mainModel.settingsServiceLife.value, country, 0)}</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('AnnualFiscalDeprecation') + Fmt.getCurrency()}</TableCell>
                                    <TableCell id="td_MCACEDIT-optimizationFiscalDepreciation" align="right">{Fmt.round(mainModel.optimizationFiscalDepreciation.value, country, 0)}</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('TaxableSavings') + Fmt.getCurrency()}</TableCell>
                                    <TableCell id="td_MCACEDIT-optimizationTaxableSavings" align="right">{Fmt.round(mainModel.optimizationTaxableSavings.value, country, 0)}</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('TaxOnProfit') + Fmt.getCurrency()}</TableCell>
                                    <TableCell id="td_MCACEDIT-optimizationTaxProfit" align="right">{Fmt.round(mainModel.optimizationTaxProfit.value, country, 0)}</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('AnnualSavingsAfterTaxes') + Fmt.getCurrency()}</TableCell>
                                    <TableCell id="td_MCACEDIT-optimizationSavingsAfterTaxes" align="right">{Fmt.round(mainModel.optimizationSavingsAfterTaxes.value, country, 0)}</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('PaybackTime')}</TableCell>
                                    <TableCell id="td_MCACEDIT-optimizationPaybackTime" align="right">{Fmt.round(mainModel.optimizationPaybackTime.value, country, 1)}</TableCell>
                                    <TableCell align="left">{t('Months')}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item id="#ReplacementParts">
                    <HeadingComponent value={t('ReplacementParts')} size="h6" />
                </Grid>
                <Grid item>
                    <ReplacementParts
                        setIsDirty={() => DirtyStore.setIsDirty(postForm)}
                        disabled={denyEdit}
                        replacementPartList={replacementPartList}
                        setReplacementPartList={setReplacementPartList}
                    />
                </Grid>
                <Grid item>
                    <HeadingComponent value={t('Comment')} size="h6" />
                </Grid>
                <Grid item>
                    <TextareaAutosize disabled={denyEdit} id='textarea_MCACEDIT-comment' type="text" value={mainModel.applicationComment.value} minRows="5"
                        onChange={e => {
                            updateModel("applicationComment", e.target.value)
                        }}
                    />
                </Grid>
                <Grid item>
                    {mainModel.guid.value && <ImageViewer disabled={denyEdit} groupId={ImageBlobConstants.ApplicationPrefix + mainModel.guid.value} />}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item><ButtonComponent id='MCACEDIT-cancel' value={t('Cancel')} color="secondary" onChange={clickCancel}></ButtonComponent></Grid>
                    <Grid item><ButtonComponent id='MCACEDIT-save' value={t('Save')} color="primary" onChange={() => postForm(NavigationOption.Reload)}></ButtonComponent></Grid>
                    {!denyEdit && <Grid item><ButtonComponent id="MCACEDIT-SaveAndCopy" value={t('SaveAndCopy')} color="primary" onChange={() => postForm(NavigationOption.Copy)}></ButtonComponent></Grid>}
                    {!denyEdit && <Grid item><ButtonComponent id='MCACEDIT-saveandnew' value={t('SaveAndNew')} color="primary" onChange={() => postForm(NavigationOption.Create)}></ButtonComponent></Grid>}
                </Grid>
            </Grid>
        </div>
    )
}

