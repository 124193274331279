// @ts-expect-error not converted yet
import { checkIsNotNullOrWhitespace, checkEmail, checkPhoneNumber, checkIsDecimal, checkIsAnythingSelected,checkNumberMoreZero } from "../../../../helper/Validator";
import * as Fmt from '../../../../helper/Formatters';
import { IDropdownItem, ModelParam, Translate } from "../../../Types/Types";

export interface IExecutorModelFactory {
    id: ModelParam<number>,
    countryID: ModelParam<string>,
    cO2GridAverage: ModelParam<string>,
    currency: ModelParam<string>,
    subsidiaryFestoName: ModelParam<string>,
    subsidiaryAddress: ModelParam<string>,
    subsidiaryCity: ModelParam<string>,
    subsidiaryTelephone: ModelParam<string | null>,
    leakageDetectionCosts: ModelParam<string>,
    repairLabourCosts: ModelParam<string>,
    travelTimeCostsPerHour: ModelParam<string>,
    sparePartsCosts: ModelParam<string>,
    costsPerDistanceUnit: ModelParam<string>,
    lumpSum: ModelParam<string>,
    equipmentServiceLife: ModelParam<string>,
    taxOnProfit: ModelParam<string>,
    paginationMaxRowCount: ModelParam<string>,
    settingsLeakageDetectedDayFlow: ModelParam<string>,
    settingsLeakageDetectedDayPerTechnician: ModelParam<string>,
    settingsRepairTimePerLeakage: ModelParam<string>,
    settingsWorkingHoursPerDayPerDetection: ModelParam<string>,
    settingsSavings1barReduction: ModelParam<string>,
    tenantId: ModelParam<string>,
    isDefaultExecutor: ModelParam<boolean>,
    festoExecutor: ModelParam<boolean>,
    hideButtonReplacementPartsList: ModelParam<boolean>,
    hideButtonBuyReplacementParts: ModelParam<boolean>,
    printSize: ModelParam<IDropdownItem| null>,
    licenseType: ModelParam<IDropdownItem| null>,

    salutation: ModelParam<number>
    firstName: ModelParam<string>,
    lastName: ModelParam<string>,
    email: ModelParam<string>,
}

export const ExecutorModelFactory = (t: Translate): IExecutorModelFactory => ({
    id: { disabled: true, idkey: "", isValid: true, label: "", validationError: "", validators: [], value: 0 },
    countryID:{ idkey: "countryID", isValid: true, label: t("Country"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsAnythingSelected], value: "" },
    cO2GridAverage: { idkey: "", isValid: true, label: t("CO2GRA") + Fmt.getUnitInBracket("co2WheightPerKwh", t), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal], value: "" },
    currency: { idkey: "", isValid: true, label: t("Currency"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], value: "" },
    subsidiaryFestoName: { idkey: "serviceprovideredit_subsidiaryFestoName", isValid: true, label: t("SubsidiaryFestoName"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], value: "" },
    subsidiaryAddress: { idkey: "serviceprovideredit_subsidiaryAddress", isValid: true, label: t("SubsidiaryAddress"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], value: "" },
    subsidiaryCity: { idkey: "serviceprovideredit_subsidiaryCity", isValid: true, label: t("SubsidiaryCity"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], value: "" },
    subsidiaryTelephone: { idkey: "serviceprovideredit_subsidiaryTelephone", isValid: true, label: t("SubsidiaryTelephone"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkPhoneNumber], value: null },
    leakageDetectionCosts: { idkey: "", isValid: true, label: t("LeakageDetectionCosts") + Fmt.getUnitWithCurrency(t("UnitDay"), t), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal], value: "" },
    repairLabourCosts: { idkey: "", isValid: true, label: t("RepairLabourCosts") + Fmt.getCurrency(), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal], value: "" },
    travelTimeCostsPerHour: { idkey: "", isValid: true, label: t("TravelTimeCostsPerHour") + Fmt.getUnitWithCurrency("hours", t), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal], value: "" },
    sparePartsCosts: { idkey: "", isValid: true, label: t("SparePartsCosts") + Fmt.getUnitWithCurrency(t("leakage"), t), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal], value: "" },
    costsPerDistanceUnit: { idkey: "", isValid: true, label: t("CostsPerDistance") + Fmt.getUnitWithCurrency("unitKmMile", t), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal], value: "" },
    lumpSum: { idkey: "", isValid: true, label: t("LumpSum") + Fmt.getUnitWithCurrency(t("UnitDay"), t), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal], value: "" },
    equipmentServiceLife: { idkey: "", isValid: true, label: t("EquipmentServiceLife"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal], value: "" },
    taxOnProfit: { idkey: "", isValid: true, label: t("TaxOnProfit") + Fmt.getPercentage(), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal], value: "" },
    paginationMaxRowCount: { idkey: "", isValid: true, label: t("MaxCountOfRows"), validationError: t("PleaseVerifyYourEntriesError"), validators: [], value: "" },
    settingsLeakageDetectedDayFlow: { idkey: "", isValid: true, label: t("SettingsLeakageDetectedDayFlow") + Fmt.addBrackets(`l/min/${t("UnitDay")}`, t), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal], value: "" },
    settingsLeakageDetectedDayPerTechnician: { idkey: "", isValid: true, label: t("SettingsLeakageDetectedDayPerTechnician") + Fmt.addBrackets(`${t("leakage")}/${t("UnitDay")}`, t), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal], value: "" },
    settingsRepairTimePerLeakage: { idkey: "", isValid: true, label: t("SettingsRepairTimePerLeakage") + Fmt.addBrackets("min", t), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal], value: "" },
    settingsWorkingHoursPerDayPerDetection: { idkey: "", isValid: true, label: t("SettingsWorkingHoursPerDayPerDetection") + Fmt.getUnitInBracket("hours", t), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal], value: "" },
    settingsSavings1barReduction: { idkey: "", isValid: true, label: t("SettingsSavings1barReduction") + Fmt.getPercentage(), validationError: t(""), validators: [checkIsDecimal], value: "" },
    tenantId: { idkey: "", isValid: true, label: t("TenantId"), validationError: t(""), validators: [], value: "" },
    isDefaultExecutor: { idkey: "", isValid: true, label: t("DefaultExecutor"), validationError: t(""), validators: [], value: false },
    festoExecutor: { idkey: "", isValid: true, label: t("FestoExecutor"), validationError: t(""), validators: [], value: false },
    hideButtonReplacementPartsList: { idkey: "", isValid: true, label: t("HideButtonReplacementPartsList"), validationError: t(""), validators: [], value: false },
    hideButtonBuyReplacementParts: { idkey: "", isValid: true, label: t("HideButtonBuyReplacementParts"), validationError: t(""), validators: [], value: false },
    printSize: { idkey: "", isValid: true, label: t("PrintSize"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsAnythingSelected], value: null },
    licenseType: { dependsOn: "festoExecutor", idkey: "", isValid: true, label: t("LicenseType"), validationError: t("PleaseVerifyYourEntriesError"), validators: [], value: null },

    salutation: { value: 0, idkey: "serviceprovideredit_salutation", label: t("Salutation"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkNumberMoreZero] },
    firstName: { idkey: "serviceprovideredit_firstName", isValid: true, label: t("FirstName"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], value: "" },
    lastName: { idkey: "serviceprovideredit_lastName", isValid: true, label: t("LastName"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], value: "" },
    email: { idkey: "serviceprovideredit_email", isValid: true, label: t("Email"), validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkEmail], value: "" },
})

export const NewExecutorProps = ["subsidiaryFestoName", "countryID", "subsidiaryAddress", "subsidiaryCity", "subsidiaryTelephone", "subsidiaryContactPerson", "subsidiaryEmail"]