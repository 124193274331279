import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import * as Mapper from '../../../helper/Mappers';
import './DistributionLine.scss';
import { TextFieldComponent, NumberInputFieldComponent, HeadingComponent, ButtonComponent, SingleSelectComponent, DatePickerComponent, AutocompleteComponent, CheckboxComponent, ListPreviousNext } from '../../../components/Reusable/';
import { Grid, TextareaAutosize } from '@mui/material';
import { API } from '../../../helper/ApiHelper';
import { DistributionLineModelFactory, applyRounding } from './DistributionLineModel';
import ReplacementParts from '../../ReplacementParts/ReplacementParts';
import MeasurementId from '../../MeasurementId/MeasurementId';
import * as ImageBlobConstants from '../../ImageViewer/Constants';
import ImageViewer from '../../ImageViewer/ImageViewer';
import { DistributionLineLayouts, OpenGlobalSnackbar, ScrollerToTag } from '../../../helper/GlobalVariables';
import { ExecutorStore, DirtyStore } from '../../../store';
import { validateSingleProperty, checkAndValidateModel } from '../../../helper/Validator';
import * as InputRestrictions from '../../../helper/InputRestrictions';
import { NavigationOption } from '../../../helper/ConstantRepository';

export default function DistributionLine(props) {
    const { denyEdit } = props;
    const anUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "air-network-analysis";
    let history = useHistory();
    const { t } = useTranslation();
    const { country, lng, id, cid } = useParams();
    const [mainModel, setMainModel] = useState((DistributionLineModelFactory(t)));
    const [replacementPartList, setReplacementPartList] = useState([]);
    const [executorUsersOptions, setexecutorUsersOptions] = useState([]);

    useEffect(() => {
        Mapper.updateExistingModelFormattingLabels(mainModel, DistributionLineModelFactory(t), setMainModel);
    }, [t]);

    useEffect(() => {
        loadData();
    }, [cid]);

    useEffect(() => {
        let execSub = ExecutorStore.executorSettings.subscribe(executorSettings => {
            if (executorSettings && executorSettings.executorUsers) setexecutorUsersOptions(executorSettings.executorUsers);
        })
        return () => execSub.unsubscribe();
    }, []);

    const loadData = async () => {
        if (cid > 0) loadExistingData();
        if (cid == 0) loadCleanPage();
    }

    const loadExistingData = () => {
        API.get(`${anUrl}/${id}/distribution-line/${cid}`).then(resp => {
            let mappedModel = Mapper.mapDataToModelValues(resp.data, DistributionLineModelFactory(t));
            let calcMappedModel = Mapper.mapMachingDataToModelValues(resp.distLineCalcs, DistributionLineModelFactory(t));
            calcMappedModel = applyRounding(calcMappedModel, country)
            setMainModel({ ...mappedModel, ...calcMappedModel });
            setReplacementPartList(resp.data.replacementPartList);
            ScrollerToTag()
        })
    }

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const loadCleanPage = async () => {
        let guid = Mapper.getNewGuid();
        let newModel = _.cloneDeep(DistributionLineModelFactory(t));
        newModel.guid.value = guid;
        setMainModel(newModel);

        setReplacementPartList([]);
        scrollTop();
    }

    const copyForm = async () => {
        Mapper.copyForm(mainModel, setMainModel);
        replacementPartList.forEach(x => x.id = 0);
        scrollTop();
    }

    const clickCancel = () => {
        history.push("/" + country + "/" + lng + '/project/' + id + '/AN' + "#DistributionLine");
    }

    const postForm = async (navigationOption) => {
        let [isValid, validatedModel] = checkAndValidateModel(mainModel, true);
        setMainModel(validatedModel);
        if (isValid) {
            let objectPostModel = Mapper.extractValuesFromModel(mainModel);
            objectPostModel.replacementPartList = replacementPartList;
            let postModel = {
                ProjectId: id,
                DistributionLine: objectPostModel
            };

            let method = mainModel.id.value == 0 ? "post" : "put";

            await API({
                url: `${anUrl}/${id}/distribution-line/${mainModel.id.value}`,
                method,
                data: postModel,
            }).then(async (componentId) => {
                DirtyStore.setIsDirty(false);
                OpenGlobalSnackbar("success", t("SaveSuccessful"));
                switch (navigationOption) {
                    case NavigationOption.Reload:
                        history.push(`/${country}/${lng}/project/${id}/AN/DistributionLine/${componentId}`);
                        break;
                    case NavigationOption.Create:
                        if (cid == 0) loadCleanPage();
                        history.push(`/${country}/${lng}/project/${id}/AN/DistributionLine/0`);
                        break;
                    case NavigationOption.Copy:
                        await copyForm();
                        break;
                    default:
                        break;
                }
            });
        }
        else {
            OpenGlobalSnackbar("danger", t('ModelInvalidError'));
        }
        return isValid;
    }

    const updateModel = (property, value) => {
        mainModel[property].value = value;
        validateSingleProperty(mainModel, property);
        setMainModel({ ...mainModel });
        DirtyStore.setIsDirty(postForm);
    }

    const updateSelectModel = (property, value, values) => {
        mainModel[property].value = values.find(x => x.id == value);
        validateSingleProperty(mainModel, property);
        setMainModel({ ...mainModel });
        DirtyStore.setIsDirty(postForm);
    }

    return (
        <div className="distribution-line">
            <Grid container spacing={3} direction="column">
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item id="#DistributionLine">
                            <HeadingComponent value={t('DistributionLine')} size="h6" />
                        </Grid>
                        <Grid item>
                            <ListPreviousNext />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MeasurementId disabled={denyEdit} element={mainModel.measurementId} onChange={e => updateModel("measurementId", e)} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <DatePickerComponent
                                disabled={denyEdit}
                                t={t}
                                model={mainModel.dateOfDetection}
                                onChange={e => updateModel("dateOfDetection", e)}
                                maxDate={new Date()}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AutocompleteComponent disabled={denyEdit} options={executorUsersOptions} t={t} model={mainModel.detectedBy} onInputChange={(e, value) => {
                                updateModel("detectedBy", value);
                            }} />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxComponent model={mainModel.isRepairDone} onChange={e => { updateModel("isRepairDone", e.target.checked); }} />
                        </Grid>
                        {mainModel && mainModel.isRepairDone && mainModel.isRepairDone.value &&
                            <Grid item xs={12}>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <DatePickerComponent
                                            t={t}
                                            model={mainModel.repairDate}
                                            onChange={e => updateModel("repairDate", e)}
                                            maxDate={new Date()}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <AutocompleteComponent options={executorUsersOptions} t={t} model={mainModel.repairedBy} onInputChange={(e, value) => { updateModel("repairedBy", value); }} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextareaAutosize
                                            id='textarea-DLINE-repairComment'
                                            placeholder={t("Comment")}
                                            width="100%"
                                            minRows="5"
                                            type="text"
                                            value={mainModel.repairComment.value}
                                            onChange={e => updateModel("repairComment", e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={mainModel.name} onChange={e => updateModel("name", e.target.value)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={mainModel.building} onChange={e => {
                                updateModel("building", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.pipeDiameter} onChange={e => {
                                updateModel("pipeDiameter", InputRestrictions.Decimal(e.target.value))
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SingleSelectComponent disabled={denyEdit} model={mainModel.distributionLineLayout} t={t} listFromDb={DistributionLineLayouts} onChange={e => {
                                updateSelectModel("distributionLineLayout", e.target.value, DistributionLineLayouts)
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={mainModel.pipeMaterial} onChange={e => {
                                updateModel("pipeMaterial", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.totalPipeLength} onChange={e => {
                                updateModel("totalPipeLength", InputRestrictions.Decimal(e.target.value))
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextFieldComponent disabled t={t} model={mainModel.pipeVolume} onChange={e => {
                        updateModel("pipeVolume", e.target.value)
                    }} />
                </Grid>
                <Grid item id="#ReplacementParts">
                    <HeadingComponent value={t('ReplacementParts')} size="h6" />
                </Grid>
                <Grid item>
                    <ReplacementParts
                        setIsDirty={() => DirtyStore.setIsDirty(postForm)}
                        disabled={denyEdit}
                        replacementPartList={replacementPartList}
                        setReplacementPartList={setReplacementPartList}
                    />
                </Grid>
                <Grid item>
                    <HeadingComponent value={t('Comment')} size="h6" />
                </Grid>
                <Grid item>
                    <TextareaAutosize disabled={denyEdit} id="textarea_DLINE-comment" type="text" value={mainModel.comment.value} minRows="5"
                        onChange={e => {
                            updateModel("comment", e.target.value)
                        }}
                    />
                </Grid>
                <Grid item>
                    {mainModel.guid.value && <ImageViewer groupId={ImageBlobConstants.DirstibutionLinePrefix + mainModel.guid.value} />}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item><ButtonComponent id="DLINE-Cancel" value={t('Cancel')} color="secondary" onChange={clickCancel}></ButtonComponent></Grid>
                    <Grid item><ButtonComponent id="DLINE-Save" value={t('Save')} color="primary" onChange={() => postForm(NavigationOption.Reload)}></ButtonComponent></Grid>
                    {!denyEdit && <Grid item><ButtonComponent id="DLINE-SaveAndCopy" value={t('SaveAndCopy')} color="primary" onChange={() => postForm(NavigationOption.Copy)}></ButtonComponent></Grid>}
                    {!denyEdit && <Grid item><ButtonComponent id="DLINE-SaveAndNew" value={t('SaveAndNew')} color="primary" onChange={() => postForm(NavigationOption.Create)}></ButtonComponent></Grid>}
                </Grid>
            </Grid>
        </div>
    )
}

