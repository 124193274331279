import React, { useState, useEffect } from 'react'
import { Grid, TableCell, TableRow, TableHead, TableBody, Table, TableContainer, Paper, IconButton, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { API } from '../../../../helper/ApiHelper';
import { SimpleCheckbox } from '../../../Reusable'
import { OpenGlobalSnackbar } from '../../../../helper/GlobalVariables';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { UserStore } from '../../../../store'
import { HasUserRole } from '../../../../helper/AuthorizationHelper/AuthHelper'
import * as Roles from '../../../../helper/AuthorizationHelper/AuthConstatants'
// @ts-expect-error not converted yet
import TableHeadingContent from '../../../Reusable/TableHeadingContent/TableHeadingContent';
import { FilterModel } from "../../../Reusable/TableHeadingContent/GlobalFilterModel"
import { useGlobalState } from 'state-pool';
import { CountryParam, LanguageParam } from '../../../Types/Types';
import { IExecutorSettings } from '../../../../store/ExecutorStore';

export default function OrganizationsSettings() {
    const execUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "executor-management"
    const { t } = useTranslation();
    const { country, lng } = useParams<CountryParam & LanguageParam>();
    const history = useHistory();
    const [mainModel, setMainModel] = useState<IExecutorSettings[]>([]);
    const [filterData, setFilterData] = useState<IExecutorSettings[]>([]);
    const [page, setPage] = useGlobalState("ManageExecutorsPage", { default: 0, persist: false });
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [checkedArray, setCheckedArray] = useState<number[]>([]);
    const [checkedArrayKey, setcheckedArrayKey] = useState<number[]>([]);
    const [user, setUser] = useState({})

    useEffect(() => {
        loadExistingData();
    }, []);

    useEffect(() => {
        const userSub = UserStore.user.subscribe(user => {
            setUser(user);
        })
        return () => userSub.unsubscribe();
    })

    const loadExistingData = () => {
        API.get(`${execUrl}/executors`).then(resp => {
            setMainModel(resp)
            setFilterData(resp)
        });
    }


    const handleCheck = (checkId: number, checkIndex: number) => {
        const isAlready = checkedArray.indexOf(checkId);
        const isAlreadyKEY = checkedArray.indexOf(checkIndex);
        if (isAlready < 0) {
            checkedArray.push(checkId);
            checkedArrayKey.push(checkIndex);
        }
        else {
            checkedArray.splice(isAlready, 1);
            checkedArrayKey.splice(isAlreadyKEY, 1);
        }
        checkedArray.sort(function (a, b) { return b - a });
        checkedArrayKey.sort(function (a, b) { return b - a });
        setCheckedArray(checkedArray);
        setcheckedArrayKey(checkedArrayKey);
    }

    const deleteRequest = async () => {
        const dataUpload = {
            Ids: mainModel.filter((x) => checkedArray.some(c => c == x.id)).map(x => x.id)
        };
        API.post(`${execUrl}/executors/delete`, dataUpload).then(() => {
            setCheckedArray([]);
            loadExistingData();
            OpenGlobalSnackbar("success", t("DeleteSuccesfull"));
        })
    };


    const filterFunction = (tempdata: any) => {
        setMainModel(tempdata);
        setPage(0);
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isCheckedForDeletion = (index: number) => {
        return checkedArray.some(x => x == index)
    }

    const handleEditExecutor = (id : number) => {
        history.push("/" + country + "/" + lng + '/SettingsPanel/ExecutorManagement/' + id + '/AddEditExecutor');
    }
    return (
        <div>
            <React.Fragment>
                <Grid item>
                    <TableHeadingContent
                        clickDelete={deleteRequest}
                        clickAdd={() => handleEditExecutor(0)}
                        defaultData={mainModel}
                        filterData={filterData}
                        onFilterFunction={filterFunction}
                        filterModel={FilterModel.ExecutorManagement}
                        tableId={"ExecutorManagement"}
                    />
                </Grid>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>{t("CompanyName")}</TableCell>
                                <TableCell>{t("Country")}</TableCell>
                                <TableCell>{t("Address")}</TableCell>
                                <TableCell>{t("City")}</TableCell>
                                <TableCell>{t("Telephone")}</TableCell>
                                <TableCell>{t("FirstName")}</TableCell>
                                <TableCell>{t("LastName")}</TableCell>
                                <TableCell>{t("Email")}</TableCell>
                                <TableCell>{t("IsFestoExecutor")}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {mainModel.length > 0 &&
                                mainModel
                                    .slice(page * rowsPerPage, rowsPerPage + page * rowsPerPage)
                                    .map((item, index) => (
                                        <TableRow key={index + 1}>
                                            <TableCell>
                                                {
                                                    (item.festoExecutor == true && !HasUserRole([Roles.SuperAdmin], user)) ?
                                                        null :
                                                        <SimpleCheckbox
                                                            id={"CAG-checkbox" + index}
                                                            key={Math.random()}
                                                            defaultChecked={isCheckedForDeletion(
                                                                page * rowsPerPage + index
                                                            )}
                                                            color="default"
                                                            onChange={() => handleCheck(item.id ?? 0, index)}
                                                        ></SimpleCheckbox>}
                                            </TableCell>
                                            <TableCell id={"id" + index}>
                                                {item.subsidiaryFestoName}
                                            </TableCell>
                                            <TableCell id={"id" + index}>
                                                {item.countryID}
                                            </TableCell>
                                            <TableCell id={"id" + index}>
                                                {item.subsidiaryAddress}
                                            </TableCell>
                                            <TableCell id={"id" + index}>
                                                {item.subsidiaryCity}
                                            </TableCell>
                                            <TableCell id={"id" + index}>
                                                {item.subsidiaryTelephone}
                                            </TableCell>
                                            <TableCell id={"id" + index}>
                                                {item.firstName}
                                            </TableCell>
                                            <TableCell id={"id" + index}>
                                                {item.lastName}
                                            </TableCell>
                                            <TableCell id={"id" + index}>
                                                {item.email}
                                            </TableCell>
                                            <TableCell id={"id" + index}>
                                                {item.festoExecutor ? <DoneIcon /> : <CloseIcon />}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    id={"btn_CAG-edit" + index}
                                                    onClick={() => {
                                                        handleEditExecutor(item.id ?? 0);
                                                    }}
                                                    size="large"
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                    component="div"
                    count={mainModel && mainModel.length > 0 ? mainModel.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </React.Fragment>
        </div>
    );
}
