import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material';
import { TextFieldComponent, HeadingComponent, ButtonComponent, ModalComponent, SingleSelectComponent, CheckboxComponent, SingleSelectComponent2 } from '../../../../Reusable'
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { API } from '../../../../../helper/ApiHelper';
// @ts-expect-error not converted yet
import * as Mapper from '../../../../../helper/Mappers';
import { OpenGlobalSnackbar, Salutations, UserRole, UserRoles } from '../../../../../helper/GlobalVariables';
import { ExecutorUserModelFactory, IExecutorUserModelFactory } from './ExecutorUserModel'
import { UserStore } from '../../../../../store'
import { CidParam, CountryParam, IdParam, LanguageParam } from '../../../../Types/Types';

export default function AddEditExecutorUser() {
    const execUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "executor-management"
    const { t } = useTranslation();
    const { country, lng, id, cid } = useParams<CountryParam & LanguageParam & IdParam & CidParam>();
    const history = useHistory();
    const [mainModel, setMainModel] = useState<IExecutorUserModelFactory>(ExecutorUserModelFactory(t));
    const [modalModel] = useState({ open: false, message: "" });

    const loadExistingData = () => {
        API.get(`${execUrl}/executor/${id}/user/${cid}`).then(resp => {
            const mappedModel = Mapper.mapDataToModelValues(resp, ExecutorUserModelFactory(t));
            setMainModel({ ...mappedModel });
        })
    }

    useEffect(() => {
        Mapper.updateExistingModelFormattingLabels(mainModel, ExecutorUserModelFactory(t), setMainModel);
    }, [t]);

    useEffect(() => {
        if (Number(cid) > 0)
            loadExistingData()
        else
            loadClean()
    }, []);

    const updateModel = (property: keyof IExecutorUserModelFactory, value: number | string | boolean) => {
        mainModel[property].value = value;
        mainModel[property].isValid = true;
        if (mainModel[property].validators.length > 0)
            mainModel[property].validators.forEach((v: (value: any, p2?: void | undefined) => boolean) => {
                if (v(value) === false) {
                    mainModel[property].isValid = false;
                }
            });
        setMainModel({ ...mainModel });
    }

    const clickCancelModalOk = async (): Promise<void> => {
        history.push("/" + country + "/" + lng + '/projectsandrequests');
        scrollTop();
    };

    const validateFormModel = () => {
        let isValid = true;
        Object.values(mainModel).forEach((m: IExecutorUserModelFactory[keyof IExecutorUserModelFactory]) => {
            if (m.validators.length > 0) {
                m.validators.forEach((v: (value: any, p2?: void | undefined) => boolean) => {
                    if (v(m.value) === false) {
                        isValid = false;
                        m.isValid = false;
                    }
                });
            }
        });
        setMainModel({ ...mainModel });
        return isValid;
    };


    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const loadClean = () => {
        setMainModel(mainModel)
    }

    const postForm = () => {
        const data = Mapper.extractValuesFromModel(mainModel);
        const method = Number(cid) == 0 ? "post" : "put"
        API({
            url: `${execUrl}/executor/${id}/user/${cid}`, //AddExecutorUser
            method,
            data,
        }).then(() => {
            OpenGlobalSnackbar("success", t("SaveSuccessful"));
            UserStore.initUser();
            history.push("/" + country + "/" + lng + '/SettingsPanel/ExecutorManagement/' + id + '/AddEditExecutor');
        })
    }

    const clickCancel = () => {
        history.push("/" + country + "/" + lng + '/SettingsPanel/ExecutorManagement/' + id + '/AddEditExecutor');
    }


    const saveForm = () => {
        if (validateFormModel() === true) postForm();
        else OpenGlobalSnackbar("danger", t('ModelInvalidError'));
    }

    const updateSelectModel = (value: number, values: UserRole[]) => {
        mainModel.role.value = values.find(x => x.id == value);
        mainModel.role.isValid = true;
        setMainModel({ ...mainModel });
    }

    return (
        <div>
            <ModalComponent model={{ clickOk: clickCancelModalOk, ...modalModel }} />

            <div className="country-settings">
                <Grid container spacing={3} direction="column">
                    <Grid item xs={12}>
                        <Grid container direction="row" justifyContent="space-between">
                            <Grid item xs={12} md={6}>
                                <HeadingComponent value={t("AddEditExcutorUser")} size="h6" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} direction="row">
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={3} direction="column">
                                    <Grid item xs={12}>
                                        <SingleSelectComponent2
                                            t={t}
                                            disabled={Number(cid) != 0}
                                            model={mainModel.salutation}
                                            listFromDb={Salutations}
                                            onChange={(e) => updateModel('salutation', e.target.value as number)}
                                            variant={'standard'} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextFieldComponent
                                            t={t}
                                            disabled={Number(cid) != 0}
                                            model={mainModel.firstName}
                                            onChange={e =>
                                                updateModel("firstName", e.target.value)
                                            }
                                            id="addedituser_firstName"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextFieldComponent
                                            t={t}
                                            model={mainModel.lastName}
                                            disabled={Number(cid) != 0}
                                            onChange={e =>
                                                updateModel("lastName", e.target.value)
                                            }
                                            id="addedituser_lastName"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextFieldComponent
                                            t={t}
                                            model={mainModel.email}
                                            disabled={Number(cid) != 0}
                                            onChange={e =>
                                                updateModel("email", e.target.value)
                                            }
                                            id="addedituser_email"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CheckboxComponent
                                            model={mainModel.isEmailNotification}
                                            onChange={e =>
                                                updateModel("isEmailNotification", e.target.checked)
                                            }
                                            id="usersettings_isEmailNotification"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SingleSelectComponent
                                            model={mainModel.role}
                                            listFromDb={UserRoles.filter(x => x.value == "ExecutorAdmin" || x.value == "Technician")}
                                            label={t("Role")}
                                            t={t}
                                            onChange={(e: { target: { value: number; }; }) => {
                                                updateSelectModel(
                                                    e.target.value,
                                                    UserRoles
                                                );
                                            }}
                                            id="addedituser_userrole"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CheckboxComponent
                                            model={mainModel.isDefaultExecutor}
                                            onChange={e => {
                                                updateModel("isDefaultExecutor", e.target.checked);
                                            }}
                                            id="addedituser_isdefaultexecutor"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item>
                            <ButtonComponent
                                value={t("Cancel")}
                                color="secondary"
                                onChange={clickCancel}
                                id="addedituser_cancel"
                            ></ButtonComponent>
                        </Grid>
                        <Grid item>
                            <ButtonComponent
                                value={t("Save")}
                                color="primary"
                                onChange={saveForm}
                                id="addedituser_save"
                            ></ButtonComponent>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

