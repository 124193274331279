import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Grid, FormHelperText, TextareaAutosize } from '@mui/material';
import * as Mapper from '../../helper/Mappers';
import { TextFieldComponent, NumberInputFieldComponent, HeadingComponent, CheckboxComponent, ButtonComponent, SingleSelectComponent, PhoneNumberInputField, ModalComponent, AutocompleteDropdown } from '../../components/Reusable/'
import { useParams, useHistory, useLocation } from "react-router-dom";
import { API } from '../../helper/ApiHelper';
import { FormatDate } from '../../helper/DateHelper';
import { ProjectModelFactory, setTravelValidators } from './ProjectModel';
import { UserRoleTable } from './UserRoleTable/UserRoleTable';
import { UserRoleModel } from './UserRoleTable/UserRoleModel';
import { checkAndValidateModel, checkAndValidateModelArray, validateModel } from '../../helper/Validator';
import { ProjectSettingsModelFactory } from './ProjectSettingsModel';
import { OpenGlobalSnackbar, MeasurementSystems, Salutations } from '../../helper/GlobalVariables';
import { ProjectStore, ExecutorStore, ProjectSettingsTrigger, DirtyStore, CountryListStore } from '../../store';
import * as Consts from '../../helper/ConstantRepository';
import * as Fmt from '../../helper/Formatters';
import * as InputRestrictions from '../../helper/InputRestrictions';
import { saveAs } from 'file-saver';
import './ProjectSettings.css';
import { checkEmail, checkIsNotNullOrWhitespace } from '../../helper/Validator';
import { useIsMount } from '../../helper/UseIsMount';

export default function ProjectSettings(props) {
    const { denyEdit } = props;
    const psUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "project-settings";
    const projectManagementUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "project-management/";

    const { t } = useTranslation();
    let { country, lng, id } = useParams();
    let history = useHistory();
    const isMount = useIsMount();
    const location = useLocation();
    const [reloadPin, setReloadPin] = useState(0);
    const [model, setModel] = useState({});
    const [reload, setReload] = useState(false);
    const [projectSettings, setProjectSettings] = useState();
    const [userList, setUserList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [leakageDetectionDays, setLeakageDetectionDays] = useState("");
    const [projectInfo, setProjectInfo] = useState({});
    const [fullAudit, setFullAudit] = useState(false);
    const [licenseTypeList, setLicenseTypeList] = useState([]);
    const [executorList, setExecutorList] = useState([]);
    const [chosenExecutor, setChosenExecutor] = useState(null);
    const [pickedProjectExecutor, setPickedProjectExecutor] = useState(0);
    const [countryList, setCountryList] = useState([]);
    const [modalModel, setModalModel] = useState({
        open: false,
        message: t("AreYouSureYouWantToTransferProjectToAnotherExecutor?"),
        cancelText: t("No"),
        okText: t("Yes"),

    });

    useEffect(() => {
        Mapper.updateExistingModelFormattingLabels(model, ProjectModelFactory(t), setModel);
        Mapper.updateArrayFormattingLabels(userList, UserRoleModel(t), setUserList);
        Mapper.updateExistingModelFormattingLabels(projectSettings, ProjectSettingsModelFactory(t), setProjectSettings);
    }, [t]);

    useEffect(() => {
        if (!isMount)
            CountryListStore.initCountryList(lng);
    }, [lng]);

    useEffect(() => {
        let execSub1 = ExecutorStore.executorList.subscribe(list => {
            setExecutorList(list);
        })
        let execSub2 = ExecutorStore.executorSelection.subscribe(executorId => setChosenExecutor(executorId));
        ProjectSettingsTrigger.reload(0);
        let projSub1 = ProjectSettingsTrigger.reloadPin.subscribe(pin => {
            if (pin > 0 && pin != reloadPin) {
                setReloadPin(pin);
                loadExistingData();
            }
        });
        let projSub2 = ProjectStore.ProjectInfo.subscribe(projInfo => setProjectInfo(projInfo));

        CountryListStore.initCountryList(lng);
        let countryListSub = CountryListStore.countryList.subscribe(list => {
            setCountryList(list);
        })

        loadExistingData();

        return () => {
            execSub1.unsubscribe();
            execSub2.unsubscribe();
            projSub1.unsubscribe();
            projSub2.unsubscribe();
            countryListSub.unsubscribe();
        }
    }, []);

    const updateExecutor = (selection) => {
        if (selection == null) return;
        setChosenExecutor(selection.id)
        if (selection.id <= 0) return;
        let executor = executorList.find((x) => x.id == selection.id);
        projectSettings.subsidiaryFestoName.value = executor.value;
        projectSettings.subsidiaryAddress.value = executor.subsidiaryAddress;
        projectSettings.subsidiaryCountry.value = executor.subsidiaryCountry;
        projectSettings.subsidiaryCity.value = executor.subsidiaryCity;
        projectSettings.subsidiaryTelephone.value = executor.subsidiaryTelephone;

        projectSettings.salutation.value = executor.Salutation;
        projectSettings.firstName.value = executor.FirstName;
        projectSettings.lastName.value = executor.LastName;
        projectSettings.email.value = executor.email;

        Mapper.updateExistingModelFormattingLabels(projectSettings, ProjectSettingsModelFactory(t), setProjectSettings);
        setIsDirtyLogic();
    }


    const loadExistingData = () => {
        API.get(psUrl + '/' + id).then(resp => {
            setLicenseTypeList(resp.licenseTypes);
            setLeakageDetectionDays(resp.leakageDetectionDays);
            setChosenExecutor(resp.executorId);
            let mappedModel = Mapper.mapDataToModelValues(resp, ProjectModelFactory(t));
            mappedModel.licenseTypeId.value = resp.license?.id;
            if (resp.license?.id == Consts.FullAudit) setFullAudit(true);
            if (resp.licenseTypes == null && resp.license != null) setLicenseTypeList([resp.license]);
            if (location.hash.includes('validate')) mappedModel = validateModel(mappedModel);
            setModel(mappedModel);

            let mappedUsers = Mapper.mapArrayDataToArrayModel(resp.userList, UserRoleModel(t));
            setUserList(mappedUsers);

            let mappedSettings = Mapper.mapDataToModelValues(resp.projectSettings, ProjectSettingsModelFactory(t));
            setProjectSettings(mappedSettings);

            setIsLoaded(true);
        });
    }

    const clickCancel = () => {
        history.push("/" + country + "/" + lng + '/projectsandrequests');
    }

    const updateModel = (property, value) => {
        Mapper.updateModel(property, value, model, setModel);
        setIsDirtyLogic();
    }

    const updateProjectSettings = (property, value) => {
        Mapper.updateModel(property, value, projectSettings, setProjectSettings);
        setIsDirtyLogic();
    }

    const updateUserList = (users) => {
        setReload(true);
        setUserList([...users]);
        setIsDirtyLogic();
    }

    let extractColumn = (arr, column) => arr.map(x => x[column]);

    const deleteAssinedRoles = (array) => {
        if (array.length > 0) {
            let data = Mapper.extractValuesFromModelOfArray(array);
            var ids = extractColumn(data, 'id');
            let postModel = {
                ProjectId: id,
                IndexArray: ids
            };

            API.post(`${psUrl}/${id}/readers`, postModel).then(() => {
                OpenGlobalSnackbar("success", t("DeleteSuccesfull"));
            })
        }
    }

    const updateLicenseType = (value) => {
        model.licenseTypeId.value = value;
        if (value == Consts.FullAudit) {
            var services = ["isCompressedAirEnergyEfficiencyPreAuditSelected", "isAnalysisOfTheCompressedAirGenerationSelected", "isCompressedAirQualityAnalysisSelected", "isAirNetworkAnalysisSelected",
                "isMachineAnalysisForEnergyEfficiencySelected"]
            services.forEach(s => { if (model[s].value) model[s].value = false })
        }
        setFullAudit(value == Consts.FullAudit ? true : false);
        setModel({ ...model });
        setReload(true);
        setIsDirtyLogic();
    }

    const isCheckBoxGroupValid = () => {
        return !(!model.isCompressedAirEnergyEfficiencyPreAuditSelected.value &&
            !model.isAnalysisOfTheCompressedAirGenerationSelected.value &&
            !model.isCompressedAirQualityAnalysisSelected.value &&
            !model.isAirNetworkAnalysisSelected.value &&
            !model.isMachineAnalysisForEnergyEfficiencySelected.value &&
            !model.isLeakageDetectionAndDocumentationSelected.value);
    }

    const isCompressedAirEnergyEfficiencyPreAuditSelected = () => {
        model.isCompressedAirEnergyEfficiencyPreAuditSelected.value = model.isAnalysisOfTheCompressedAirGenerationSelected.value &&
            model.isCompressedAirQualityAnalysisSelected.value &&
            model.isAirNetworkAnalysisSelected.value &&
            model.isMachineAnalysisForEnergyEfficiencySelected.value &&
            model.isLeakageDetectionAndDocumentationSelected.value;
    }

    const validateCheckBoxGroup = () => {
        var isValid = isCheckBoxGroupValid();
        model.isCompressedAirEnergyEfficiencyPreAuditSelected.isValid = isValid;
        model.isAnalysisOfTheCompressedAirGenerationSelected.isValid = isValid;
        model.isCompressedAirQualityAnalysisSelected.isValid = isValid;
        model.isAirNetworkAnalysisSelected.isValid = isValid;
        model.isMachineAnalysisForEnergyEfficiencySelected.isValid = isValid;
        model.isLeakageDetectionAndDocumentationSelected.isValid = isValid;
        isCompressedAirEnergyEfficiencyPreAuditSelected();
    }

    const save = () => {
        let mainModel = setTravelValidators(model);
        let [isValidModel, validatedModel] = checkAndValidateModel(mainModel, true);
        let [isValidUsers, validatedUsers] = checkAndValidateModelArray(userList, true);
        let [isValidSettings, validatedSettings] = checkAndValidateModel(projectSettings, true);
        setModel(validatedModel);
        setUserList(validatedUsers);
        setProjectSettings(validatedSettings);
        let allValid = isValidModel && isValidUsers && isValidSettings && isUserListValid();

        if (allValid) {
            let data = Mapper.extractValuesFromModel(validatedModel);
            let userData = Mapper.extractValuesFromModelOfArray(validatedUsers);
            let settingsData = Mapper.extractValuesFromModel(validatedSettings);
            data["userList"] = userData;
            data["executorId"] = chosenExecutor;
            data["projectSettings"] = settingsData;
            API.put(psUrl + '/' + id, data).then(() => {
                DirtyStore.setIsDirty(false);
                OpenGlobalSnackbar("success", t('SavedSuccessfully'));
                if (reload === true) window.location.reload();
            });
        }
        else {
            OpenGlobalSnackbar("danger", t('ModelInvalidError'));
        }
        return allValid;
    }

    const updateMeasurementSystem = (value) => {
        let selectValue = MeasurementSystems.find(x => x.id == value);
        Fmt.setUnitData({ measurementSystem: selectValue?.value, currency: projectSettings.currency.value })
        projectSettings.measurementSystem.value = selectValue;
        projectSettings.measurementSystem.isValid = true;
        projectSettings.measurementSystem.validators.map((v) => { if (v(projectSettings.measurementSystem.value) === false) projectSettings.measurementSystem.isValid = false; });
        Mapper.updateExistingModelFormattingLabels(projectSettings, ProjectSettingsModelFactory(t), setProjectSettings);
        setIsDirtyLogic();
    }

    const setIsDirtyLogic = () => {
        if (projectInfo?.projectRights?.hasRestrictedSettingsEdit || projectInfo?.projectRights?.isExecutorAdmin || projectInfo?.projectRights?.isTechnician)
            DirtyStore.setIsDirty(save);
    }

    const updateProjectExecutor = async (executorId) => {
        await API.get(`${psUrl}/${id}/change-executor/${executorId}`);
        setPickedProjectExecutor(0);
        save();
        window.location.reload();
    }

    const checkSaveOrChangeExecutor = () => {
        if (pickedProjectExecutor > 0)
            setModalModel({ ...modalModel, open: true, clickOk: () => updateProjectExecutor(pickedProjectExecutor) });
        else
            save();
    }

    const printProjectUserAccessLog = async () => {
        const projectUserAccessLog = await API.get(`${projectManagementUrl}${id}/access-log`);
        const csvData = [[t("Name"), t("Email"), t("TimeAccessed")]].concat(projectUserAccessLog.map(x => [x.userFullName, x.userEmail, x.timeAccessed]));
        const csvContent = "data:text/csv;charset=utf-8," + csvData.map(e => e.join(";")).join("\n");
        saveAs(csvContent, "project-user-acess-log.csv");
    }

    const isUserListValid = () => {
        let isValid = true;
        userList.forEach(row => {
            Object.keys(row).forEach(key => {
                if (row[key].validators.length > 0) {
                    row[key].isValid = true;
                    row[key].validators.forEach(f => {
                        if (f(row[key].value) == false) {
                            row[key].isValid = isValid = false;
                            //need to skip validation for already existing non festo emails
                            if (key === "email" && row.id.value > 0 && checkIsNotNullOrWhitespace(row[key].value) && checkEmail(row[key].value))
                                row[key].isValid = isValid = true;
                        }
                    });
                }
            });
        });
        updateUserList(userList);
        return isValid;
    }

    return (
        <div className="project-settings">
            <ModalComponent model={modalModel} />
            {isLoaded ?
                <React.Fragment>
                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12} sm={6} id="#ProjectSettings">
                            <HeadingComponent value={t('ProjectSettings')} size="h6" />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <TextFieldComponent disabled={denyEdit} t={t} model={model.projectName} onChange={e => updateModel("projectName", e.target.value)} />
                                </Grid>
                                <Grid item>
                                    <TextFieldComponent disabled={denyEdit} t={t} model={model.customer} onChange={e => updateModel("customer", e.target.value)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <TextFieldComponent disabled={denyEdit} t={t} model={model.sapOrderNo} onChange={e => updateModel("sapOrderNo", e.target.value)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextareaAutosize
                                disabled={denyEdit}
                                placeholder={model.freeTextField?.label ?? ""}
                                id="textarea_PS-freeTextField"
                                type="text"
                                value={model.freeTextField?.value ?? ""}
                                minRows="5"
                                onChange={e => updateModel("freeTextField", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={6}>
                            <SingleSelectComponent
                                disabled
                                model={model.licenseTypeId}
                                listFromDb={_.cloneDeep(licenseTypeList).map(item => {
                                    item.value = (projectInfo.projectInfo?.licenceExpirationTime == undefined ? 
                                        (item.id == Consts.FullAudit ? t("LicenseFullAudit") : t("LicenseLeakageDetection")) : 
                                        (item.id == Consts.FullAudit ? t("SubscriptionFullAudit") : t("SubscriptionLeakageDetection")))
                                    return item;
                                })}
                                onChange={e => updateLicenseType(e.target.value)}
                                noFirstEmpty={true}
                            />
                        </Grid>
                        {projectInfo.projectInfo?.licenceExpirationTime != undefined &&
                            <Grid item xs={6}>
                                <HeadingComponent value={t("ExpirationDate") + ": " + FormatDate(projectInfo.projectInfo.licenceExpirationTime)} size="h4" />
                            </Grid>
                        }
                    </Grid>
                    {!fullAudit &&
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <CheckboxComponent
                                    disabled={denyEdit}
                                    name="cb_documentation"
                                    model={model.isLeakageDetectionAndDocumentationSelected}
                                    onChange={e => {
                                        model.isLeakageDetectionAndDocumentationSelected.value = e.target.checked;
                                        validateCheckBoxGroup();
                                        setModel({ ...model });
                                        setReload(true);
                                    }}
                                />
                                {!isCheckBoxGroupValid() && !fullAudit && <FormHelperText error={true}>{t('ProjectErrorNoneServicesSelected')}</FormHelperText>}
                            </Grid>
                        </Grid>
                    }
                    {fullAudit &&
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <CheckboxComponent
                                    disabled={denyEdit}
                                    name="cb_preAudition"
                                    model={model.isCompressedAirEnergyEfficiencyPreAuditSelected}
                                    onChange={e => {
                                        model.isCompressedAirEnergyEfficiencyPreAuditSelected.value = e.target.checked;
                                        model.isAnalysisOfTheCompressedAirGenerationSelected.value = e.target.checked;
                                        model.isCompressedAirQualityAnalysisSelected.value = e.target.checked;
                                        model.isAirNetworkAnalysisSelected.value = e.target.checked;
                                        model.isMachineAnalysisForEnergyEfficiencySelected.value = e.target.checked;
                                        model.isLeakageDetectionAndDocumentationSelected.value = e.target.checked;
                                        validateCheckBoxGroup();
                                        setModel({ ...model });
                                        setReload(true);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <CheckboxComponent
                                    disabled={denyEdit}
                                    name="cb_airGeneration"
                                    model={model.isAnalysisOfTheCompressedAirGenerationSelected}
                                    onChange={e => {
                                        model.isAnalysisOfTheCompressedAirGenerationSelected.value = e.target.checked;
                                        validateCheckBoxGroup();
                                        setModel({ ...model });
                                        setReload(true);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <CheckboxComponent
                                    disabled={denyEdit}
                                    name="cb_qualityAnalysis"
                                    model={model.isCompressedAirQualityAnalysisSelected}
                                    onChange={e => {
                                        model.isCompressedAirQualityAnalysisSelected.value = e.target.checked;
                                        validateCheckBoxGroup();
                                        setModel({ ...model });
                                        setReload(true);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <CheckboxComponent
                                    disabled={denyEdit}
                                    name="cb_networkAnalysis"
                                    model={model.isAirNetworkAnalysisSelected}
                                    onChange={e => {
                                        model.isAirNetworkAnalysisSelected.value = e.target.checked;
                                        validateCheckBoxGroup();
                                        setModel({ ...model });
                                        setReload(true);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <CheckboxComponent
                                    disabled={denyEdit}
                                    name="cb_energEfficiency"
                                    model={model.isMachineAnalysisForEnergyEfficiencySelected}
                                    onChange={e => {
                                        model.isMachineAnalysisForEnergyEfficiencySelected.value = e.target.checked;
                                        validateCheckBoxGroup();
                                        setModel({ ...model });
                                        setReload(true);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <CheckboxComponent
                                    disabled={denyEdit}
                                    name="cb_documentation"
                                    model={model.isLeakageDetectionAndDocumentationSelected}
                                    onChange={e => {
                                        model.isLeakageDetectionAndDocumentationSelected.value = e.target.checked;
                                        validateCheckBoxGroup();
                                        setModel({ ...model });
                                        setReload(true);
                                    }}
                                />
                            </Grid>
                            {!isCheckBoxGroupValid() && <FormHelperText error={true}>{t('ProjectErrorNoneServicesSelected')}</FormHelperText>}
                        </Grid>
                    }

                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <label>{t('EstimatedLeakageDetectionDays') + " " + leakageDetectionDays}</label>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <Grid container direction="column" spacing={3}>
                                <Grid item id="#CustomerAccess">
                                    <HeadingComponent value={t('CustomerAccess')} size="h6" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <UserRoleTable
                                denyEdit={!(projectInfo?.projectRights?.hasRestrictedSettingsEdit || projectInfo?.projectRights?.isExecutorAdmin || projectInfo?.projectRights?.isTechnician)}
                                data={userList}
                                updateData={updateUserList}
                                deleteData={deleteAssinedRoles}
                                isUserListValid={isUserListValid}
                            />
                        </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <Grid container direction="column" spacing={3}>
                                <Grid item id="#TravelInformation">
                                    <HeadingComponent value={t('TravelInformation')} size="h6" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12}>
                            <CheckboxComponent
                                disabled={denyEdit}
                                model={model.travelCostsKnown}
                                onChange={e => updateModel("travelCostsKnown", e.target.checked)}
                            />
                        </Grid>
                        {model.travelCostsKnown.value === true ?
                            <React.Fragment>
                                <Grid item xs={12} sm={6}>
                                    <NumberInputFieldComponent disabled={denyEdit} t={t} model={model.travelLumpSum} onChange={e => updateModel("travelLumpSum", InputRestrictions.Decimal(e.target.value, 1))} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <NumberInputFieldComponent disabled={denyEdit} t={t} model={model.travelTimeCosts} onChange={e => updateModel("travelTimeCosts", InputRestrictions.Decimal(e.target.value, 1))} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <NumberInputFieldComponent disabled={denyEdit} t={t} model={model.travelCosts} onChange={e => updateModel("travelCosts", InputRestrictions.Decimal(e.target.value, 1))} />
                                </Grid>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Grid item xs={12} sm={6}>
                                    <NumberInputFieldComponent disabled={denyEdit} t={t} model={model.travelTime} onChange={e => updateModel("travelTime", InputRestrictions.Decimal(e.target.value, 1))} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <NumberInputFieldComponent disabled={denyEdit} t={t} model={model.travelDistance} onChange={e => updateModel("travelDistance", InputRestrictions.Decimal(e.target.value, 1))} />
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>

                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12} sm={6} container direction="column" spacing={3}>
                            <Grid item id="#FestoSubsidiaryinformation">
                                <HeadingComponent value={t('ProjectExecutedBy')} size="h6" className={{ paddingBottom: 25, paddingTop: 25 }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12} md={3}>
                            {
                                executorList.length > 0 &&
                                <Grid item className="report-executorSelect">
                                    <AutocompleteDropdown
                                        model={{ label: t("Executor"), idkey: "report-header-executorselect", value: chosenExecutor, isValid: true }}
                                        onChange={(e) => updateExecutor(e)}
                                        options={executorList}
                                        optionName="value"
                                        optionKey="id"
                                        portaldisable={true}
                                        TranslateFirstTwo={true}
                                    />
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} sm={9} />
                        <Grid item xs={12} sm={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={projectSettings.subsidiaryFestoName} onChange={e => updateProjectSettings("subsidiaryFestoName", e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={projectSettings.subsidiaryAddress} onChange={e => updateProjectSettings("subsidiaryAddress", e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AutocompleteDropdown
                                disabled={denyEdit}
                                model={projectSettings.subsidiaryCountry}
                                label={t('Country')}
                                options={countryList}
                                optionName="countryName"
                                optionKey="countryCode"
                                onChange={e => {
                                    updateProjectSettings("subsidiaryCountry", e?.countryCode)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={projectSettings.subsidiaryCity} onChange={e => updateProjectSettings("subsidiaryCity", e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <PhoneNumberInputField disabled={denyEdit} model={projectSettings.subsidiaryTelephone} onChange={e => updateProjectSettings("subsidiaryTelephone", e)} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={projectSettings.email} onChange={e => updateProjectSettings("email", e.target.value)} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SingleSelectComponent
                                t={t}
                                model={projectSettings.salutation}
                                listFromDb={Salutations}
                                onChange={(e) => updateProjectSettings('salutation', e.target.value)}
                                variant={'standard'} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={projectSettings.firstName} onChange={e => {
                                updateProjectSettings("firstName", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={projectSettings.lastName} onChange={e => {
                                updateProjectSettings("lastName", e.target.value)
                            }} />
                        </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12} sm={6} container direction="column" spacing={3}>
                            <Grid item id="#ProjectSpecificSettings">
                                <HeadingComponent value={t('ProjectSpecificSettings')} size="h6" className={{ paddingBottom: 25, paddingTop: 25 }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <SingleSelectComponent
                                disabled={denyEdit || (!projectInfo?.projectRights?.hasRestrictedSettingsEdit && !projectInfo?.projectRights?.isExecutorAdmin)}
                                id='input_measurementSystem'
                                listFromDb={MeasurementSystems}
                                t={t}
                                model={projectSettings.measurementSystem}
                                onChange={e => updateMeasurementSystem(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldComponent
                                disabled={denyEdit}
                                t={t}
                                model={projectSettings.currency}
                                onChange={e => Mapper.updateModelUnitData("currency", e.target.value, projectSettings, setProjectSettings, ProjectSettingsModelFactory, t)}
                            />
                        </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={5}>
                        <Grid item>
                            <HeadingComponent value={t('LeakageDetectionCostsHeader')} size="h6" />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <TextFieldComponent disabled={denyEdit} t={t} model={projectSettings.leakageDetectionCosts} onChange={e => updateProjectSettings("leakageDetectionCosts", e.target.value)} />
                                </Grid>
                                <Grid item>
                                    <TextFieldComponent disabled={denyEdit} t={t} model={projectSettings.repairLabourCosts} onChange={e => updateProjectSettings("repairLabourCosts", e.target.value)} />
                                </Grid>
                                <Grid item>
                                    <TextFieldComponent disabled={denyEdit} t={t} model={projectSettings.travelTimeCostsPerHour} onChange={e => updateProjectSettings("travelTimeCostsPerHour", e.target.value)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <TextFieldComponent disabled={denyEdit} t={t} model={projectSettings.sparePartsCosts} onChange={e => updateProjectSettings("sparePartsCosts", e.target.value)} />
                                </Grid>
                                <Grid item>
                                    <NumberInputFieldComponent disabled={denyEdit} t={t} model={projectSettings.costsPerDistanceUnit} onChange={e => updateProjectSettings("costsPerDistanceUnit", InputRestrictions.Decimal(e.target.value))} />
                                </Grid>
                                <Grid item>
                                    <TextFieldComponent disabled={denyEdit} t={t} model={projectSettings.lumpSum} onChange={e => updateProjectSettings("lumpSum", e.target.value)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12} sm={6} container direction="column" spacing={3}>
                            <Grid item>
                                <HeadingComponent value={t('PaybackTime')} size="h6" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <TextFieldComponent disabled={denyEdit} t={t} model={projectSettings.equipmentServiceLife} onChange={e => updateProjectSettings("equipmentServiceLife", e.target.value)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <TextFieldComponent disabled={denyEdit} t={t} model={projectSettings.taxOnProfit} onChange={e => updateProjectSettings("taxOnProfit", e.target.value)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {(projectInfo?.projectRights?.hasRestrictedSettingsEdit === true || projectInfo?.projectRights?.isExecutorAdmin || projectInfo?.projectRights?.isTechnician) &&
                        <Grid container spacing={5} direction="row">
                            <Grid item xs={12}>
                                <HeadingComponent value={t('RestrictedSettings')} size="h6" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container direction="column" spacing={3}>
                                    <Grid item>
                                        <NumberInputFieldComponent
                                            disabled={denyEdit}
                                            t={t}
                                            model={projectSettings.settingsLeakageDetectedDayFlow}
                                            onChange={e => updateProjectSettings("settingsLeakageDetectedDayFlow", InputRestrictions.Decimal(e.target.value))}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <NumberInputFieldComponent
                                            disabled={denyEdit}
                                            t={t}
                                            model={projectSettings.settingsLeakageDetectedDayPerTechnician}
                                            onChange={e => updateProjectSettings("settingsLeakageDetectedDayPerTechnician", InputRestrictions.Decimal(e.target.value))}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <NumberInputFieldComponent
                                            disabled={denyEdit}
                                            t={t}
                                            model={projectSettings.settingsRepairTimePerLeakage}
                                            onChange={e => updateProjectSettings("settingsRepairTimePerLeakage", InputRestrictions.Decimal(e.target.value))}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={3} direction="column">
                                    <Grid item>
                                        <NumberInputFieldComponent
                                            disabled={denyEdit}
                                            t={t}
                                            model={projectSettings.settingsWorkingHoursPerDayPerDetection}
                                            onChange={e => updateProjectSettings("settingsWorkingHoursPerDayPerDetection", InputRestrictions.Decimal(e.target.value))}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <NumberInputFieldComponent
                                            disabled={denyEdit}
                                            t={t}
                                            model={projectSettings.settingsSavings1barReduction}
                                            onChange={e => updateProjectSettings("settingsSavings1barReduction", InputRestrictions.Decimal(e.target.value))}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <HeadingComponent value={t('TransferProjectTo')} size="h6" />
                    </Grid>
                    <Grid item xs={4}>
                        <SingleSelectComponent
                            model={{ label: t("Executor"), idkey: "PS-executorSelectId", value: pickedProjectExecutor, isValid: true, validationError: "", validators: [] }}
                            t={t}
                            listFromDb={executorList.filter(e => e.id > 0)}
                            onChange={e => setPickedProjectExecutor(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row" justifyContent="space-between">
                            <Grid item>
                                {(projectInfo?.projectRights?.isSuperAdmin || projectInfo?.projectRights?.isExecutorAdmin) &&
                                    <ButtonComponent
                                        style={{ marginLeft: 0 }}
                                        id="PrintProjectUserAccessLog"
                                        value={t('PrintProjectUserAccessLog')}
                                        color="primary"
                                        onChange={printProjectUserAccessLog}
                                    />
                                }
                            </Grid>
                            <Grid item>
                                <Grid container direction="row">
                                    <Grid item>
                                        <ButtonComponent
                                            id="PS-cancel"
                                            value={t('Cancel')}
                                            color="secondary"
                                            onChange={clickCancel}
                                        />
                                    </Grid>
                                    {(projectInfo?.projectRights?.isSuperAdmin || projectInfo?.projectRights?.isExecutorAdmin || projectInfo?.projectRights?.isTechnician) &&
                                        <Grid item>
                                            <ButtonComponent
                                                id="PS-saveProjectSettings"
                                                value={t('Save')}
                                                color="primary"
                                                onChange={checkSaveOrChangeExecutor}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
                : <React.Fragment />}
        </div>);
}