// @ts-expect-error not converted yet
import { checkIsNotNullOrWhitespace } from "../../../../../helper/Validator";
import { ModelParam, Translate } from "../../../../Types/Types";

export interface IExecutorDocumentationFactory {
    id: ModelParam<number>,
    name: ModelParam<string>,
    url: ModelParam<string>,
    isBlob: ModelParam<boolean>,
}

export interface IExecutorDocumentationModel {
    id: number,
    name: string,
    url: string,
    isBlob: boolean,
}

export const ExecutorDocumentationModel = (t: Translate): IExecutorDocumentationFactory => ({
    id: { value: 0, isValid: true, validationError: "", validators: [], disabled: true, idkey: "", label: "" },
    name: { value: "", label: t("Name"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], idkey: "" },
    url: { label: t("Link"), value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [urlValidator], idkey: "" },
    isBlob: { value: false, isValid: true, validationError: "", validators: [], idkey: "", label: "" }
})

const urlValidator = (value: string, model: IExecutorDocumentationFactory | void) => model?.isBlob.value || checkIsNotNullOrWhitespace(value);